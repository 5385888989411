import React, { useEffect, useState } from 'react';
import CardFrame from '@/containers/CardFrame/CardFrame';
import MarkdownBox from '@/components/MarkdownBox/MarkdownBox';
import Button from '@/components/Button/Button';

function Base() {
  const [cardList, setCardList] = useState([]);

  const getContent = (action) => {
    let request = new XMLHttpRequest();

    //NETLIFY using apiary for now.
    request.open(
      'GET',
      'https://private-848dd-mattienodj.apiary-mock.com/read'
    );

    //LOCALDEV
    //request.open('GET', 'http://127.0.0.1:4010/' + action);

    request.setRequestHeader('Accept', 'application/json');

    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        let responseJSON = JSON.parse(this.responseText);
        setCardList(responseJSON);
      }
    };

    request.send();
  };

  useEffect(() => {
    getContent('read');
  }, []);

  let Cards;

  if (cardList) {
    Cards = cardList.map((cardElement, keyIdx) => {
      const markdown = cardElement.bodyText;
      const title = cardElement.title;

      return pug`
        CardFrame(key=keyIdx)
          h1 #{title}

          MarkdownBox(markdown=markdown)

          Button(message="Delete")

          Button(message="Schedule")

          Button(message="Delegate")
      `;
    });
  }

  return Cards;
}

export default Base;
