import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MUIBox from '@material-ui/core/Box';
import ReactMarkdown from 'react-markdown';

const StyledMarkdownBox = styled(MUIBox)`
  background-color: #efefef;
  color: #333;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  &:hover {
    background-color: #5469d4;
  }
`;

const MarkdownBox = ({ markdown, classes }) => pug`
  StyledMarkdownBox() 
    ReactMarkdown(source=markdown)
`;

MarkdownBox.propTypes = {
  markdown: PropTypes.string,
  classes: PropTypes.array,
};

MarkdownBox.defaultProps = {
  markdown: '#Hello',
  classes: [],
};

export default MarkdownBox;
