import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';

const StyledButton = styled(MUIButton)`
  background-color: #006699;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  &:hover {
    background-color: #5469d4;
  }
`;

const Button = ({ message, classes }) => pug`
  StyledButton()(className=classes) #{message}
`;

Button.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.array,
};

Button.defaultProps = {
  message: 'Hello',
  classes: ['big', 'bold', 'm50'],
};

export default Button;
