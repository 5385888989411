import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCardFrame = styled.section`
  border: 1px solid #dedede;
  color: #000;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
`;

const CardFrame = ({ cardframetype, children }) => pug`
  StyledCardFrame(cardframetype=cardframetype) #{children}
`;

CardFrame.propTypes = {
  cardframetype: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CardFrame.defaultProps = {
  cardframetype: 'default',
  children: [],
};

export default CardFrame;
